const boxShadow = '0px 0px 5px -1px rgba(0,0,0,0.75)';

const changeButton = {
  padding: '5px',
  background: 'rgba(255, 255, 255, 0.7)',
  border: 'none',
  fontWeight: 'bold',
  boxShadow: boxShadow,
  marginLeft: 5,
  marginRight: 5
};

export const modal = {
  overflow: 'hidden',
  background: 'rgba(0, 0, 0, 0.7)',
  position: 'absolute',
  height: '100%',
  width: '100%',
  zIndex: '4444',
  top: '0',
  left: '0',
  padding: '5px',
  textAlign: 'center',
  color: 'white'
};

export const buttonsContainer = {
  position: 'relative',
  float: 'right',
  background: 'rgba(255, 255, 255, 0.7)',
  borderRadius: 5,
  boxShadow: boxShadow
};

export const next = {
  ...changeButton
};

export const prev = {
  ...changeButton
};

export const close = {
  ...changeButton
};

export const resize = {
  ...changeButton
};

export const header = {
  position: 'absolute',
  top: 15,
  width: 'calc(100% - 10px)',
  zIndex: 5555
};

export const canvas_cc = {
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  background: 'transparent',
  position: 'relative'
};

export const create_text = {
  position: 'absolute',
  zIndex: 333,
  padding: 5,
  backgroundColor: '#eee',
  width: '250px',
  minHeight: 155,
  borderRadius: 4,
  boxShadow: boxShadow
};

export const create_text_area = {
  resize: 'none',
  width: '100%',
  height: '100px',
  border: '1px solid lightgray'
};

export const colors_list = {
  width: '100%',
  margin: 0,
  padding: 0,
  textAlign: 'center'
};

export const colors_list_item = {
  display: 'inline-block',
  margin: '2.5px'
};

export const color_select = {
  borderRadius: '50%',
  width: '20px',
  height: '20px',
  border: '1px solid white',
  cursor: 'pointer',
  boxShadow: boxShadow
  /* webkitTransition: "width 0.4s, height 0.4s",
  transition: "width 0.4s, height 0.4s" */
};

export const color_selected = {
  margin: '0 3px 3px',
  position: 'relative',
  float: 'left',
  background: 'gray',
  padding: '5px',
  boxShadow: boxShadow
};

export const buttons = {
  border: 'none',
  background: 'transparent',
  color: 'black',
  margin: '0px 10px'
};

export default {
  modal,
  next,
  prev,
  header,
  close,
  resize,
  canvas_cc,
  create_text,
  create_text_area,
  color_select,
  color_selected,
  colors_list,
  colors_list_item,
  buttons,
  buttonsContainer
};
