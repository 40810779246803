import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';

class UploadImage extends React.Component {
  uploadClick() {
    let input = document.getElementById(`${this.props.inputName}`);
    input.click();
  }

  render() {
    return (
      <div className="fileinput text-center">
        <div
          className={
            'thumbnail avatar-image' +
            (this.props.avatar ? ' img-circle circle-round' : '')
          }
          style={{ backgroundImage: `url('${this.props.image}')` }}
        >
          <input
            type="file"
            id={`${this.props.inputName}`}
            name={`${this.props.inputName}`}
            accept="image/png, image/jpeg"
            hidden={true}
            onChange={event => this.props.onChange(event)}
          />
        </div>
        <div>
          <Button round onClick={() => this.uploadClick()}>
            {this.props.textButton}
          </Button>
        </div>
      </div>
    );
  }
}

UploadImage.propTypes = {
  avatar: PropTypes.bool
};

export default UploadImage;
