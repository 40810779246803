import {
  baseUrl,
  createClient,
  createQueryString,
  get,
  postForm,
  post
} from './util';

const client = createClient('users');

client.photo = (id, file) => {
  if (!file) return;
  let fd = new FormData();
  fd.append('photo', file);
  return postForm(`${baseUrl}/users/${id}/photo`, fd);
};

client.logo = (id, file) => {
  if (!file) return;
  let fd = new FormData();
  fd.append('photo', file);
  return postForm(`${baseUrl}/users/${id}/logo`, fd);
};

client.location = params =>
  get(`${baseUrl}/users/location${createQueryString(params)}`);

client.fcmTokens = data => post(`${baseUrl}/users/fcmTokens`, data);

export default client;
