import { createClient, baseUrl, get, createQueryString } from './util';

const client = createClient('pros');

client.supervisorsContacts = params =>
  get(`${baseUrl}/pros/contacts/supervisors${createQueryString(params)}`);

client.clientsContacts = params =>
  get(`${baseUrl}/pros/contacts/clients${createQueryString(params)}`);

client.projects = params =>
  get(`${baseUrl}/pros/projects${createQueryString(params)}`);

client.visits = params =>
  get(`${baseUrl}/pros/visits${createQueryString(params)}`);

client.events = params =>
  get(`${baseUrl}/pros/events${createQueryString(params)}`);

// client.postulate = id => post(`${baseUrl}/pro/postulate/${id}`);

// client.requests = params =>
//   get(`${baseUrl}/pros/postulations${createQueryString(params)}`);

export default client;
