import React, { Component } from 'react';
import styles from './styles';
import Konva from 'konva';
import {
  Stage,
  Layer,
  Text,
  Circle,
  Rect,
  Image as ImgKonva
} from 'react-konva';
import Point from './point';
Konva.pixelRatio = 1;

class ImageCanvas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: '',
      imgObj: null,
      loading: true,
      newElement: '',
      stageAction: '',
      containerSize: {
        w: '100%',
        h: '100%'
      },
      imageSize: {
        w: 1000,
        h: 1000
      },
      newPoint: null,
      editPoint: null,
      icons: {},
      move: true,
      elements: []
    };

    this.cc = React.createRef();
    this.stage = React.createRef();
    this.layer = React.createRef();
    this.tooltipLayer = React.createRef();
    this.tooltip = React.createRef();
    this.tooltipBack = React.createRef();
    // used in scroll functionality
    this.lastDist = false;
    this.point = null;
    // used to add a new point
    this.newPointCircle = null;
  }

  componentDidMount() {
    let { imageUrl, elements, icons, height, resize } = this.props;
    this.load({
      imageUrl,
      elements,
      icons,
      resize,
      containerSize: {
        w: '100%',
        h: height
      }
    });
  }

  componentWillReceiveProps({ imageUrl, elements, icons, height, resize }) {
    this.load({
      imageUrl,
      elements,
      icons,
      resize,
      containerSize: {
        w: '100%',
        h: height
      }
    });
  }

  load(data) {
    this.getImageData(data.imageUrl)
      .then(imageData => {
        this.setState(
          ps => ({
            ...ps,
            imageSize: { w: imageData.w, h: imageData.h },
            imgObj: imageData.img,
            ...data,
            loading: false
          }),
          () => {
            if (data.resize) this.resizeStage();
          }
        );
      })
      .catch(err => {
        console.log('Image not loaded');
      });
  }

  overElement(e) {
    var mousePos = this.mouseRelativePosition();
    // change text tooltip
    this.tooltip.current.position({
      x: mousePos.x + 35,
      y: mousePos.y - 20
    });
    this.tooltip.current.text(e.text);
    this.tooltip.current.show();
    // change background tooltip
    this.tooltipBack.current.position({
      x: mousePos.x + 25,
      y: mousePos.y - 30
    });

    let height =
      this.tooltip.current.textArr.length > 1
        ? this.tooltip.current.textArr.length * 30 + 30
        : 50;
    this.tooltipBack.current.width(425);
    this.tooltipBack.current.height(height);
    this.tooltipBack.current.show();
    // update tooltip layer
    this.tooltipLayer.current.batchDraw();
  }

  outElement() {
    this.tooltip.current.hide();
    this.tooltipBack.current.hide();
    this.tooltipLayer.current.batchDraw();
  }

  onClickElement = (item, event) => {
    let editPoint = {
      key: item.key,
      name: item.key,
      text: item.text,
      fill: item.fill,
      x: item.x,
      y: item.y,
      top: event.evt.clientY,
      left: event.evt.clientX
    };

    this.setState(ps => ({
      ...ps,
      editPoint
    }));
  };

  onClickStage = () => {
    this.setState(ps => ({ ...ps, newPoint: null, editPoint: null }));
    let position = this.mouseRelativePosition();
    let key = position.x + '_' + position.y;

    let newPoint = {
      key: key,
      name: key,
      text: '',
      fill: 'black',
      x: position.x,
      y: position.y,
      top: this.stage.current.getStage().getPointerPosition().y + 'px',
      left: this.stage.current.getStage().getPointerPosition().x + 'px'
    };

    this.setState(ps => ({
      ...ps,
      newPoint
    }));
  };

  buildPoints = elements => {
    return elements.map(e => (
      <Circle
        key={e.key}
        name={e.key}
        x={e.x}
        y={e.y}
        radius={20}
        fill={e.fill}
        onMouseMove={() => this.overElement(e)}
        onMouseOver={() => this.overElement(e)}
        onMouseOut={() => this.outElement()}
        onClick={event => this.onClickElement(e, event)}
        onTap={event => this.onClickElement(e, event)}
        shadowColor={'black'}
        shadowBlur={10}
        shadowOpacity={0.6}
        transformsEnabled={'position'}
        perfectDrawEnabled={false}
      />
    ));
  };

  savePoint = point => {
    let { elements, editPoint } = this.state;
    if (editPoint) {
      let index = elements.findIndex(e => e.key === point.key);
      elements[index] = point;
    } else {
      elements.push(point);
    }
    this.props.savePoints(elements);
    this.setState({ elements, newPoint: null, editPoint: null });
  };

  deletePoint = point => {
    let { elements, editPoint } = this.state;
    if (editPoint) {
      let index = elements.findIndex(e => e.key === point.key);
      elements.splice(index, 1);
    }
    this.props.savePoints(elements);
    this.setState({ elements, newPoint: null, editPoint: null });
  };

  render() {
    let {
      imageSize,
      containerSize,
      imgObj,
      move,
      elements,
      newPoint,
      editPoint
    } = this.state;

    elements = this.buildPoints(elements);

    return (
      <div style={{ overflow: 'hidden', position: 'relative' }}>
        <div
          ref={this.cc}
          id={'canvas-cc'}
          className={'canvas-container'}
          style={{
            ...styles.canvas_cc,
            overflow: 'hidden',
            height: containerSize.h,
            width: containerSize.w
          }}
        >
          {imageSize.w && imageSize.h ? (
            <Stage
              ref={this.stage}
              draggable={move}
              key={'canvas-stage'}
              width={window.innerWidth - 10}
              height={window.innerHeight - 10}
              // used for handle add new points
              onDblClick={this.onClickStage}
              onDblTap={this.onClickStage}
              // used to change img size
              onContentWheel={this.pinchZoomWheelEvent}
              onContentTouchMove={this.pinchZoomTouchEvent}
              onContentTouchEnd={this.pinchZoomTouchEventEnd}
            >
              <Layer ref={this.layer}>
                {imgObj ? (
                  <ImgKonva
                    x={0}
                    y={0}
                    image={imgObj}
                    width={imageSize.w}
                    height={imageSize.h}
                  />
                ) : null}

                {elements}
              </Layer>

              <Layer ref={this.tooltipLayer}>
                <Rect
                  ref={this.tooltipBack}
                  key={'tooltip_back_key'}
                  name={'tooltip_back_key'}
                  fill={'rgba(255,255,255,0.9)'}
                  visible={false}
                  padding={5}
                  transformsEnabled={'position'}
                  shadowBlur={10}
                  cornerRadius={5}
                  width={400}
                  height={0}
                  x={0}
                  y={0}
                />
                <Text
                  ref={this.tooltip}
                  key={'tooltip_key'}
                  name={'tooltip_key'}
                  x={0}
                  y={0}
                  width={400}
                  text={'-'}
                  fontSize={30}
                  fill={'black'}
                  visible={false}
                  transformsEnabled={'position'}
                  //stroke={"white"}
                  //padding={5}
                />
              </Layer>
            </Stage>
          ) : null}

          {newPoint || editPoint ? (
            <Point
              savePointButton={this.props.savePointButton}
              deletePointButton={this.props.deletePointButton}
              closePointButton={this.props.closePointButton}
              point={newPoint ? newPoint : editPoint}
              edit={newPoint ? false : true}
              savePoint={point => this.savePoint(point)}
              deletePoint={point => this.deletePoint(point)}
              closeModalPoint={() =>
                this.setState({ newPoint: null, editPoint: null })
              }
            />
          ) : null}
        </div>
      </div>
    );
  }

  /** Get mouse position inside Stage */
  mouseRelativePosition() {
    // the function will return pointer position relative to the passed node
    var transform = this.stage.current.getAbsoluteTransform().copy();
    // to detect relative position we need to invert transform
    transform.invert();
    // get pointer (say mouse or touch) position
    var pos = this.stage.current.getPointerPosition();
    // now we find relative point
    let point = transform.point(pos);
    return { x: point.x, y: point.y };
  }

  pinchZoomWheelEvent = e => {
    let wheelEvent = e.evt;
    let stage = this.stage.current;

    wheelEvent.preventDefault();
    const oldScale = stage.scaleX();

    const pointer = stage.getPointerPosition();
    const startPos = {
      x: pointer.x / oldScale - stage.x() / oldScale,
      y: pointer.y / oldScale - stage.y() / oldScale
    };

    const deltaYBounded = !(wheelEvent.deltaY % 1)
      ? Math.abs(Math.min(-10, Math.max(10, wheelEvent.deltaY)))
      : Math.abs(wheelEvent.deltaY);
    const scaleBy = 1.01 + deltaYBounded / 70;
    const newScale =
      wheelEvent.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;

    let height = newScale * stage.attrs.height;

    if (height > 50 && height < 5500) {
      stage.scale({ x: newScale, y: newScale });

      const newPosition = {
        x: (pointer.x / newScale - startPos.x) * newScale,
        y: (pointer.y / newScale - startPos.y) * newScale
      };
      stage.position(newPosition);
      stage.batchDraw();
    }
  };

  getDistance(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  clientPointerRelativeToStage(clientX, clientY, stage) {
    return {
      x: clientX - stage.getContent().offsetLeft,
      y: clientY - stage.getContent().offsetTop
    };
  }

  pinchZoomTouchEvent = e => {
    let evt = e.evt;
    let stage = this.stage.current;

    const t1 = evt.touches[0];
    const t2 = evt.touches[1];
    if (t1 && t2) {
      evt.preventDefault();
      evt.stopPropagation();
      const oldScale = stage.scaleX();

      const dist = this.getDistance(
        { x: t1.clientX, y: t1.clientY },
        { x: t2.clientX, y: t2.clientY }
      );
      if (!this.lastDist) this.lastDist = dist;
      const delta = dist - this.lastDist;

      const px = (t1.clientX + t2.clientX) / 2;
      const py = (t1.clientY + t2.clientY) / 2;
      const pointer =
        this.point || this.clientPointerRelativeToStage(px, py, stage);

      if (!this.point) this.point = pointer;

      const startPos = {
        x: pointer.x / oldScale - stage.x() / oldScale,
        y: pointer.y / oldScale - stage.y() / oldScale
      };

      const scaleBy = 1.01 + Math.abs(delta) / 100;
      const newScale = delta < 0 ? oldScale / scaleBy : oldScale * scaleBy;

      let height = newScale * stage.attrs.height;

      if (height > 50 && height < 5500) {
        stage.scale({ x: newScale, y: newScale });

        const newPosition = {
          x: (pointer.x / newScale - startPos.x) * newScale,
          y: (pointer.y / newScale - startPos.y) * newScale
        };

        stage.position(newPosition);
        stage.batchDraw();
      }

      this.lastDist = dist;
    }
  };

  pinchZoomTouchEventEnd = e => {
    this.lastDist = 0;
    this.point = undefined;
  };

  /** Get image information to set width and height of Stage */
  getImageData(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.onload = function() {
        resolve({ w: this.width, h: this.height, img });
      };
      img.src = url;
    });
  }

  /** Resize stage to fix Plan inside container width */
  resizeStage() {
    let { imageSize } = this.state;
    let width = this.cc.current.clientWidth;
    let height = this.cc.current.clientHeight;
    let stage = this.stage.current;

    let newWidthScale = 1;
    let newHeigthScale = 1;
    let newScale = 1;

    // change image size to fix inside container width
    if (imageSize.w > width) {
      newWidthScale = (100 * width) / imageSize.w;
    }
    if (imageSize.w <= width) {
      newWidthScale = (100 * imageSize.w) / width;
      newWidthScale += 100;
    }
    // change image size to fix inside container heigth
    if (imageSize.h > height) {
      newHeigthScale = (100 * height) / imageSize.h;
    }
    if (imageSize.h <= height) {
      newHeigthScale = (100 * imageSize.h) / height;
      newHeigthScale += 100;
    }

    if (newHeigthScale >= 100 && newWidthScale >= 100) {
      if (newHeigthScale > newWidthScale) {
        newHeigthScale = 100;
      } else {
        newWidthScale = 100;
      }
    }

    // what to do when size is lower than screen
    newScale = newHeigthScale < newWidthScale ? newHeigthScale : newWidthScale;
    stage.scale({ x: newScale / 100, y: newScale / 100 });

    let newX = 0;
    let newY = 0;

    if (newHeigthScale < newWidthScale) {
      // move horizontal >
      let imageSize =
        this.layer.current.getChildren()[0].attrs.width * (newScale / 100);
      newX = (width - imageSize) / 2;
    } else {
      // move vertical ^
      let imageSize =
        this.layer.current.getChildren()[0].attrs.height * (newScale / 100);
      newY = (height - imageSize) / 2;
    }

    // check if image size is lower than screen to fix center on the screen
    if (newHeigthScale === 100) {
      let imageSize =
        this.layer.current.getChildren()[0].attrs.height * (newScale / 100);
      newY = (height - imageSize) / 2;
    }

    if (newWidthScale === 100) {
      let imageSize =
        this.layer.current.getChildren()[0].attrs.width *
        (newHeigthScale / 100);
      newX = (width - imageSize) / 2;
    }

    stage.absolutePosition({ x: newX, y: newY });
    stage.batchDraw();
    /* 
    let a = (stage.attrs.width * (newScale / 100)) / 2;
    let b = width / 2;
    let newX = b - a;

    a = (stage.attrs.height * (newScale / 100)) / 2;
    b = height / 2;
    let newH = b - a;

    console.log(newX, ' - ', newH);

    stage.absolutePosition({
      x: newX > 0 ? newX : newX * -1,
      y: newH > 0 ? newH : newH * -1
    });

    stage.batchDraw(); 
    
    this.setState(ps => ({
      ...ps,
      containerSize: {
        w: width,
        h: height
      },
      loading: true
    }));
    */
  }
}

export default ImageCanvas;
