import { notify } from './index';

export const all = params => (dispatch, getState, { api }) =>
  api.Users.all(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const one = id => (dispatch, getState, { api }) =>
  api.Users.one(id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const save = user => (dispatch, getState, { api }) =>
  api.Users.save(user)
    .then(response => {
      dispatch(notify('info', 'User saved'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const remove = id => (dispatch, getState, { api }) =>
  api.Users.del(id)
    .then(response => {
      dispatch(notify('info', 'User removed'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const photo = (id, file) => (dispatch, getState, { api }) =>
  api.Users.photo(id, file)
    .then(response => {
      dispatch(notify('info', 'User photo saved'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const logo = (id, file) => (dispatch, getState, { api }) =>
  api.Users.logo(id, file)
    .then(response => {
      dispatch(notify('info', 'User logo saved'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const location = params => (dispatch, getState, { api }) =>
  api.Users.location(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const fcmTokens = data => (dispatch, getState, { api }) =>
  api.Users.fcmTokens(data).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });
