import { applyMiddleware, compose, createStore } from 'redux';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';
import 'firebase/firestore';
import thunk from 'redux-thunk';
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import { reduxFirestore } from 'redux-firestore';
import api from '../api';
import reducers from '../../reducers';
import fbConfig from '../../conf/firebase';
import { createBrowserHistory } from 'history';

app.initializeApp(fbConfig);
// Initialize other services on firebase instance
const db = app.firestore();

const history = createBrowserHistory();

const config = {
  enableLogging: false, // enable/disable Firebase's database logging
  attachAuthIsReady: true, // attaches auth is ready promise to store
  firebaseStateName: 'fb'
};

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

/**
 *
 * @param helpers
 * @return {*}
 */
export default function configureStore() {
  const enhancer = composeEnhancers(
    reactReduxFirebase(app, config),
    reduxFirestore(app),
    applyMiddleware(thunk.withExtraArgument({ api, getFirebase, ...history }))
  );

  let store = createStore(reducers, enhancer);

  // Listen for auth ready (promise available on store thanks to attachAuthIsReady: true config option)
  store.firebaseAuthIsReady
    .then(() => {})
    .catch(err => {
      console.log(err);
    });

  return store;
}

export { history, db };
